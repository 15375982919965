/*
 * @Description: 路由
 * @Version: 0.0.1
 * @Author: yujiangping
 * @Date: 2024-06-11 13:49:13
 * @LastEditors: yujiangping
 * @LastEditTime: 2024-12-23 18:50:53
 */
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/PricingView.vue'
import ProductView from '../views/ProductView.vue'
import SolutionView from '../views/SolutionView.vue'
import AIService from '../views/AiServiceView.vue'
import AiDS from '../views/AiDSView.vue'
import documentView from '../views/docsView.vue'
import EducationView from '../views/EducationView.vue'
import NotFound from '../views/404.vue'
const routes = [
  {
    path: '/',
    redirect: "/solution",
  },
  {
    path: '',
    redirect: "/solution",
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: HomeView
  },
  {
    path: '/product',
    name: 'product',
    component: ProductView
  },
  {
    path: '/solution',
    name: 'solution',
    component: SolutionView
  },
  {
    path: '/aiEdu',
    name: 'aiEdu',
    component: EducationView
  },
  {
    path: '/AIService',
    name: 'AIService',
    component: AIService
  },
  {
    path: '/AiDS',
    name: 'AiDS',
    component: AiDS
  },
  {
    path: '/document',
    name: 'document',
    component: documentView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {path: '/404', name: '404', meta: {title: '404'}, component: NotFound},//
  //官方文档复制而来
  { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound },
  // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
  { path: '/:pathMatch(.*)', name: 'bad-not-found', component: NotFound },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savePosition) {
    if (savePosition) {
      //解决页面从列表页跳转到详情页返回,初始在原来位置
      return savePosition;
    }
    return { left: 0, top: 0 };
  },
  routes: routes,
  // routes
})

export default router
