<!--
 * @Description: 404
 * @Version: 0.0.1
 * @Author: yujiangping
 * @Date: 2024-12-23 18:48:28
 * @LastEditors: yujiangping
 * @LastEditTime: 2024-12-23 19:35:49
-->
<template>
    <div class="w-full div-full flex-row justify-center items-center">
        <div class="flex-col">
            <img :src="$AIPRB_IMG_PATH + '/website/404.png'" class="img404" />
            <span class="error-text">抱歉！您访问的页面不存在</span>
            <span class="time-text">{{remainingTime}}s后自动跳转首页</span>
            <a href="/" target="_self" class="btn-primary">返回首页</a>
        </div>

    </div>
</template>
<script>
export default {
    name:'404',
    data() {
        return {
            countdown: 5, // 倒计时秒数，这里设置为5秒
            intervalId: null, // 存储interval的id
        }
    },
    computed: {
        remainingTime() {
            return this.countdown;
        }
    },
    created() {
        this.intervalId = setInterval(this.countdownTimer, 1000);
    },
    methods: {
        countdownTimer() {
            if (this.countdown > 0) {
                this.countdown -= 1;
            } else {
                clearInterval(this.intervalId);
                this.$router.push('/'); // 倒计时结束后跳转到主页
            }
        },
    },
    unmounted() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    },
}
</script>
<style lang="less" scoped>
    .div-full{
        margin-top: 100px;
        height: 100% !important;
        min-height: 100%;
    }
    .img404{
        width: 392px;
        height: 172px;
    }
    .error-text{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #212332;
        line-height: 22px;
        text-align: center;
        font-style: normal;
        padding-top: 44px;
    }
    .time-text{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #A8A9AA;
        line-height: 22px;
        text-align: center;
        font-style: normal;
        padding-top: 12px;
    }
    .btn-primary{
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        background: #155EEF;
        border-radius: 6px;
        color: #fff;
        width: 122px;
        height: 32px;
        padding-left: 0;
        margin: 12px auto;
    }
</style>